<template>
<div>
  <div class="wenjuan_list">
    <div class="info">
      <h3>2020年二季度园区发展调研<a href="javascript:;" class="dload"><i></i>下载答卷数据</a></h3>
      <p class="blue"><span class="quesLink">问卷链接：</span>https://www.lzzcloud.com/mobile/aggregate.php</p>
      <p class="other">
        <span>编号：82289010  </span>
        <span>创建：2020年6月23日</span>
        <span>开始：2020年6月23日</span>
        <span>截止：2020年6月23日</span>
        <span v-if="status==1">状态：<b class="blue">调研进行中</b> </span>
        <span v-else-if="status==2">状态：<b class="green">调研结束</b> </span>
        <span v-else-if="status==3">状态：<b class="red">未启动</b> </span>
        <span v-else-if="status==4">状态：<b class="red">草稿</b> </span>
        <span>答卷：<b class="blue">526</b> 份</span>
      </p>
    </div>
    <div class="main_table">
      <div class="filtrate clearfix">
        <div class="filtrate_item">
          <span class="item_name">企业名称</span>
          <el-input v-model="input" placeholder="输入企业名称" style="width: 240px;"></el-input>
        </div>
<!--        <div class="filtrate_item">-->
<!--          <span class="item_name">是否星标</span>-->
<!--          <el-select v-model="value" placeholder="请选择" style="width: 140px;">-->
<!--            <el-option-->
<!--                v-for="item in options"-->
<!--                :key="item.value"-->
<!--                :label="item.label"-->
<!--                :value="item.value">-->
<!--            </el-option>-->
<!--          </el-select>-->
<!--        </div>-->
        <div class="filtrate_item">
          <span class="item_name">题目标题</span>
          <el-select v-model="value1" placeholder="请选择" style="width: 240px;">
            <el-option
                v-for="item in titleOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </div>
        <a class="btn" href="javascript:;">查询</a>
      </div>
      <el-table
          ref="multipleTable"
          :data="tableData"
          :border="true"
          style="width: 100%"
          @selection-change="handleSelectionChange">
        <el-table-column
            type="selection"
            width="80"
            align="center">
        </el-table-column>
<!--        <el-table-column-->
<!--            label="星标"-->
<!--            width="80"-->
<!--            align="center">-->
<!--          <template slot-scope="scope">-->
<!--            <img v-if="scope.row.star" @click="handleStarChange(scope.$index)" class="star"-->
<!--                 src="@/assets/answerSheet/star_orange_icon@2x.png" alt=""/>-->
<!--            <img v-else class="star" @click="handleStarChange(scope.$index)"-->
<!--                 src="@/assets/answerSheet/star_grey_icon@2x.png" alt=""/>-->
<!--          </template>-->
<!--        </el-table-column>-->
        <el-table-column
            prop="name"
            label="企业名称"
            min-width="100%">
        </el-table-column>
        <el-table-column
            prop="address"
            label="所在地区">
        </el-table-column>
        <el-table-column
            prop="grid"
            label="所属网格">
        </el-table-column>
        <el-table-column
            prop="manager"
            label="商企经理">
        </el-table-column>
        <el-table-column
            prop="integrity"
            label="完整度"
            width="120"
            align="center"
            class-name="color-red">
        </el-table-column>
        <el-table-column
            prop="time"
            label="提交时间"
            width="160">
        </el-table-column>
        <el-table-column
            label="操作"
            width="170">
          <template slot-scope="scope">
            <router-link :to="{ path: '/suervey', query: { manager:scope.row.manager } }" target="_blank" class="handle" @click="handleView(scope.$index, scope.row)">查看答卷</router-link>
            <span class="handle" @click="handleDownload(scope.$index, scope.row)">下载答卷</span>
          </template>
        </el-table-column>
      </el-table>
      <div class="operate">
        <div class="handle">
          <div class="all-select">
            <el-checkbox :indeterminate="isIndeterminate" v-model="allSelect" @change="toggleSelection()"></el-checkbox>
          </div>
          <div class="button" @click="toggleSelection()">全选</div>
          <div class="button">下载答卷</div>
        </div>
        <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="100"
            layout="prev, pager, next, sizes"
            :total="400">
        </el-pagination>
      </div>
    </div>
  </div>

  <!-- s-弹窗 -->
</div>
</template>

<script>
export default {
  name: "sheet",
  data(){
    return{
      input: '', //企业名称
      options: [{
        value: '选项1', //id
        label: '是'
      }, {
        value: '选项2',
        label: '否'
      }],
      titleOptions:[
        {
          value: '选项1',
          label: '题目一'
        }, {
          value: '选项2',
          label: '题目二'
        }, {
          value: '选项3',
          label: '题目三'
        }, {
          value: '选项4',
          label: '题目四'
        }, {
          value: '选项5',
          label: '题目五'
        }
      ],
      value: '', //是否星标
      value1: '', //题目标题
      status:2,
      tableData: [ //表格数据
        {
          star: false,
          name: '重庆市大足区聪聪五金制造有限公司',
          address: '江城哈尼族彝族自治县',
          grid: '昭阳区商务楼宇商企网格',
          manager: '张三丰（zhangsanfeng）',
          integrity: '98%',
          time: '2024-02-23 22:52'
        },
        {
          star: false,
          name: '重庆市大足区聪聪五金制造有限公司',
          address: '江城哈尼族彝族自治县',
          grid: '昭阳区商务楼宇商企网格',
          manager: '张三丰（zhangsanfeng）',
          integrity: '98%',
          time: '2024-02-23 22:52'
        },
        {
          star: true,
          name: '重庆市大足区聪聪五金制造有限公司',
          address: '江城哈尼族彝族自治县',
          grid: '昭阳区商务楼宇商企网格',
          manager: '张三丰（zhangsanfeng）',
          integrity: '98%',
          time: '2024-02-23 22:52'
        },

        {
          star: false,
          name: '重庆市大足区聪聪五金制造有限公司',
          address: '江城哈尼族彝族自治县',
          grid: '昭阳区商务楼宇商企网格',
          manager: '张三丰（zhangsanfeng）',
          integrity: '98%',
          time: '2024-02-23 22:52'
        },
        {
          star: false,
          name: '重庆市大足区聪聪五金制造有限公司',
          address: '江城哈尼族彝族自治县',
          grid: '昭阳区商务楼宇商企网格',
          manager: '张三丰（zhangsanfeng）',
          integrity: '98%',
          time: '2024-02-23 22:52'
        },
        {
          star: true,
          name: '重庆市大足区聪聪五金制造有限公司',
          address: '江城哈尼族彝族自治县',
          grid: '昭阳区商务楼宇商企网格',
          manager: '张三丰（zhangsanfeng）',
          integrity: '98%',
          time: '2024-02-23 22:52'
        },

        {
          star: false,
          name: '重庆市大足区聪聪五金制造有限公司',
          address: '江城哈尼族彝族自治县',
          grid: '昭阳区商务楼宇商企网格',
          manager: '张三丰（zhangsanfeng）',
          integrity: '98%',
          time: '2024-02-23 22:52'
        },
        {
          star: false,
          name: '重庆市大足区聪聪五金制造有限公司',
          address: '江城哈尼族彝族自治县',
          grid: '昭阳区商务楼宇商企网格',
          manager: '张三丰（zhangsanfeng）',
          integrity: '98%',
          time: '2024-02-23 22:52'
        },
        {
          star: true,
          name: '重庆市大足区聪聪五金制造有限公司',
          address: '江城哈尼族彝族自治县',
          grid: '昭阳区商务楼宇商企网格',
          manager: '张三丰（zhangsanfeng）',
          integrity: '98%',
          time: '2024-02-23 22:52'
        },
        {
          star: true,
          name: '重庆市大足区聪聪五金制造有限公司',
          address: '江城哈尼族彝族自治县',
          grid: '昭阳区商务楼宇商企网格',
          manager: '张三丰（zhangsanfeng）',
          integrity: '98%',
          time: '2024-02-23 22:52'
        },
        {
          star: true,
          name: '重庆市大足区聪聪五金制造有限公司',
          address: '江城哈尼族彝族自治县',
          grid: '昭阳区商务楼宇商企网格',
          manager: '张三丰（zhangsanfeng）',
          integrity: '98%',
          time: '2024-02-23 22:52'
        },
      ],
      isIndeterminate: false, // 表格是否有选中但未全选
      allSelect: false, // 全选
      multipleSelection: [], // 表格选中列表
      currentPage: 1
    }
  },
  methods:{
    handleSelectionChange(val) {
      this.multipleSelection = val;
      this.isIndeterminate = val.length > 0 && val.length < this.tableData.length;
      this.allSelect = val.length === this.tableData.length ? true : false;
      console.log(val)
    }, // 表格选中改变
    toggleSelection() {
      this.$refs.multipleTable.toggleAllSelection();
    }, // 表格全选
    handleStarChange(index) {
      this.tableData[index].star = !this.tableData[index].star;
    }, // 单个星标点击
    handleView(index,row) {
      // this.$router.push({path:'/suervey',query:{index:index}})
    },
    handleDownload() {

    },
    handleSizeChange() {

    },
    handleCurrentChange() {

    }
  }
}
</script>

<style scoped lang="scss">
::v-deep .el-select .el-input__icon {
  line-height: 34px;
}
.wenjuan_list {
  background: #fff;
  margin: 18px;
  padding-bottom: 20px;
  .info {
    padding: 19px 20px 18px;
    .quesLink{
      color: #6C7880;
    }
    h3 {
      position: relative;
      line-height: 36px;
      font-size: 20px;
    }
    .dload {
      position: absolute;
      right: 0;
      top: 1px;
      line-height: 34px;
      height: 34px;
      background: #04cd83;
      padding: 0 14px;
      border-radius: 2px;
      color: #fff;
      font-size: 12px;
      i {
        display: inline-block;
        width: 18px;
        height: 15px;
        background: url(~@/assets/statistics/analysis_icon.png) 0 -62px no-repeat;
        vertical-align: middle;
      }
      &:hover {
        background: #05c07b;
      }
    }
    p {
      line-height: 24px;
    }
    .other {
      line-height: 32px;
      color: #6C7880;
      span {
        margin-right: 40px;
      }
      b {
        font-weight: normal;
      }
    }
    .blue {
      color: #1190f3;
    }
    .green {
      color: #04cd83;
    }
    .red{
      color: #FD5451;
    }
  }
}
.main_table {
  padding: 0 20px 20px;
  .filtrate {
    margin-bottom: 20px;
    display: flex;
    .down_select {
      float: left;
      margin-right: 10px;
    }
    .btn {
      width: 70px;
      height: 34px;
      line-height: 34px;
      text-align: center;
      border-radius: 4px;
      color: #fff;
      background-color: #3984F5;
      &:hover {
        background-color: #0073cc;
      }
    }
    .filtrate_item{
      ::v-deep .el-input .el-input__inner{
        height: 34px;
        line-height: 34px;
      }
      .item_name{
        color: #21333F;
        font-size: 12px;
        margin-right: 12px;
      }
      margin-right: 40px;
    }
  }
  .operate {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    .handle {
      display: flex;
      .all-select {
        margin-right: 16px;
        padding-left: 34px;
        line-height: 32px;
      }
      .button {
        margin-right: 10px;
        padding: 0 12px;
        height: 30px;
        line-height: 30px;
        font-size: 12px;
        color: #6C7880;
        border: 1px solid #CEDAE0;
        cursor: pointer;
        &:hover {
          color: #3984F5;
          border-color: #3984F5;
        }
      }
    }
  }
  table {
    .star {
      margin-bottom: 2px;
      vertical-align: middle;
      width: 12px;
      height: 12px;
      cursor: pointer;
    }
    .handle {
      margin-right: 20px;
      color: #3984F5;
      cursor: pointer;
    }
  }
}
::v-deep .el-table {
  font-size: 12px;
  color: #242D33;
  border-color: #DCE5EC;
  &::before,
  &::after {
    background-color: #DCE5EC;
  }
  .el-table__cell {
    padding: 8px 0;
    border-color: #DCE5EC;
  }
  .cell {
    line-height: 24px;
  }
  thead {
    color: #242D33;
  }
  th.el-table__cell.is-leaf {
    border-color: #DCE5EC;
    background: #F6F7F9;
  }
  th {
    font-weight: 400;
  }
  .el-table-column--selection .cell {
    padding-right: 10px;
  }
  .el-table__body tr:hover > td.el-table__cell {
    background-color: #F6FBFE;
  }
  td.el-table__cell {
    padding: 13px 0;
  }
  td.color-red {
    color: #FD5451;
  }
}

::v-deep .el-pagination {
  padding: 0;
  color: #242D33;
  font-weight: 400;
  button {
    font-size: 12px;
    height: 32px;
    line-height: 32px;
  }
  .el-pager li {
    font-size: 12px;
    height: 32px;
    line-height: 32px;
  }
  .btn-next, .btn-prev, .el-pager li {
    border: 1px solid #CEDAE0;
    background-color: #ffffff;
    color: #242D33;
    min-width: 32px;
  }
  .btn-next:not(:disabled), .btn-prev:not(:disabled), .el-pager li {
    &:hover {
      color: #3984F5;
      border-color: #3984F5;
    }
  }
  .el-pager li:not(.disabled).active {
    background-color: #3984F5;
    border-color: #3984F5;
  }
  .btn-next.disabled, .btn-next:disabled, .btn-prev.disabled, .btn-prev:disabled, .el-pager li.disabled {
    color: #E1E8EC;
  }
  .el-pagination__sizes {
    margin-right: 0;
    height: 32px;
    line-height: 32px;
  }
  .el-select .el-input {
    margin-right: 0;
    .el-input__inner {
      font-size: 12px;
      height: 32px;
      line-height: 32px;
      &:hover {
        border-color: #3984F5;
      }
    }
  }
  .el-range-editor.is-active, .el-range-editor.is-active:hover, .el-select .el-input.is-focus .el-input__inner {
    border-color: #3984F5;
  }
}
</style>